<div>
  <div class="page">
    <h1 translate>Our available license types</h1>

    <div class="card-container" *ngFor="let product of shopData?.products | keyvalue">
      <mat-card appearance="outlined" class="package-card" *ngFor="let package of product.value.packages | keyvalue : onCompare">
        <mat-card-title>
          <div class="card-title">
            <span class="primary">{{ package.value.months }} </span>
            <span *ngIf="package.value.months === 1" translate>month</span>
            <span *ngIf="package.value.months > 1" translate>months</span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="price-row">
            <div class="card-title-sub" *ngIf="package.value.free_months">
              (<span class="primary">{{ package.value.free_months }} </span>
              <span *ngIf="package.value.free_months === 1" translate> month </span>
              <span *ngIf="package.value.free_months > 1" translate> months </span>
              <span translate>free)</span>
            </div>
            <span *ngIf="isHun && isHunCurrency; else elsePrice">{{ getGrossPrice(product.value.price, package.value.months, package.value.free_months) }}</span>
            <ng-template #elsePrice>
              <span>{{ getPrice(product.value.price, package.value.months, package.value.free_months) }} </span>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div>
      <app-etalon-info></app-etalon-info>
    </div>

    <ul class="explain-list">
      <li translate>We will reduce an amount from the indicated prices with a valid discount code!</li>
      <li translate>All license types are available for self purchase or as a bundle.</li>
      <li translate>Bundled tokens can be activated on a later date, or can be given to other users</li>
      <li translate>The nano slot amount determines how many Nano clients can be concurrently connected to the web application.</li>
      <li translate>In case of bundle purchase, each bundle token serves a single Nano slot.</li>
      <li translate>
        Please note that the maximum number of slots you can own is <b>{{ accountLicenseSlotLimit }}</b
        >. Any purchased slots above <b>{{ accountLicenseSlotLimit }}</b> will be unavailable.
      </li>
      <li>
        <p translate>
          It is possible to purchase a Nano license as a company, priced at HUF 100,000 + VAT. Depending on the quantity and license duration, we offer a discounted price to our customers.
        </p>
        <p translate>For more information or to place an order, please contact us at <a href="mailto:penzugy@calarabot.eu">penzugy&#64;calarabot.eu</a>.</p>
      </li>
      <li translate>License purchase requires the user to be <a routerLink="/login" translate>logged in</a>.</li>
    </ul>
  </div>
</div>
