<div>
  <div class="sub-page">
    <h1 translate>License Purchase</h1>

    <ng-container *ngIf="isOnApp">
      <p translate>Purchasing a license is only possible on our webpage</p>
      <ol>
        <li>
          <p><span translate>Visit </span> <a translate (click)="(openBuyLicenseInBrowser)">https://nano.clarabot.com</a></p>
        </li>
        <li><p translate>Log in to your account</p></li>
        <li><p translate>Go to the "Buy license" page</p></li>
      </ol>
      <app-etalon-info></app-etalon-info>
    </ng-container>
    <ng-container *ngIf="!isOnApp">
      <ng-container *ngIf="!checkoutCompleted">
        <small translate>Please fill out the purchase form below to buy a new Nano license.</small>

        <mat-stepper orientation="vertical" #stepper [linear]="true">
          <mat-step [stepControl]="basketForm">
            <form [formGroup]="basketForm" *ngIf="shopData">
              <ng-template matStepLabel><span translate>Nano license</span></ng-template>

              <div *ngIf="isDevOrAdmin" class="sandbox-div">
                <mat-card appearance="outlined" class="product-card">
                  <mat-card-header>
                    <mat-card-title><span translate>Sandbox</span></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <mat-checkbox color="primary" formControlName="sandbox_payment">
                      <span translate> Sandbox purchase for testing</span>
                    </mat-checkbox>
                  </mat-card-content>
                </mat-card>
              </div>

              <div class="license-form-row">
                <mat-form-field appearance="fill">
                  <mat-label><span translate>Currency</span></mat-label>
                  <mat-select formControlName="currency">
                    <mat-option value="EUR">EUR</mat-option>
                    <mat-option value="HUF">HUF</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div id="products-container" class="license-form-row">
                <mat-card appearance="outlined" class="product-card" *ngFor="let product of shopData.products | keyvalue">
                  <mat-card-header>
                    <mat-card-title><span translate>License type</span></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <mat-radio-group class="form-radio-group" formControlName="package" color="primary">
                      <mat-radio-button
                        class="form-radio-button"
                        *ngFor="let package of product.value.packages | keyvalue : onCompare"
                        [value]="package"
                        (click)="selectProductPackage(product.key, package.key, product.value)"
                      >
                        <div class="form-title">
                          {{ package.value.months }}
                          <span *ngIf="package.value.months === 1" translate>month</span>
                          <span *ngIf="package.value.months > 1" translate>months</span>

                          <ng-container *ngIf="package.value.free_months">
                            <small>
                              ({{ package.value.free_months }}
                              <span *ngIf="package.value.free_months === 1" translate> month </span>
                              <span *ngIf="package.value.free_months > 1" translate> months </span>
                              <span translate>free)</span>
                            </small>
                          </ng-container>
                        </div>
                        <div class="form-description">
                          <span *ngIf="isHun && isHunCurrency; else elsePrice">{{ getGrossPrice(product.value.price, package.value.months, package.value.free_months) }}</span>
                          <ng-template #elsePrice>
                            <span>{{ getPrice(product.value.price, package.value.months, package.value.free_months) }} </span>
                          </ng-template>
                        </div>
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="basketForm.get('package').errors?.['required'] && this.basketForm.get('package').touched"><span translate>Please select a product.</span></mat-error>
                  </mat-card-content>
                </mat-card>
              </div>

              <div class="license-form-row">
                <mat-card appearance="outlined">
                  <mat-card-header
                    ><mat-card-title><span translate>License target</span></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <mat-radio-group class="form-radio-group" color="primary" formControlName="target" (change)="targetSelectionChanged($event)">
                      <mat-radio-button class="form-radio-button" [value]="'own'">
                        <div class="form-title" translate>Self</div>
                        <div class="form-description" translate>Purchase license for yourself. This will immediately activate the license after purchase.</div>
                      </mat-radio-button>
                      <mat-radio-button class="form-radio-button" [value]="'bundle'">
                        <div class="form-title" translate>Bundle</div>
                        <div class="form-description" translate>Purchase as a bundle. You will be able to activate the bundle tokens later, or send them to other users.</div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </mat-card-content>
                </mat-card>
              </div>

              <div class="license-form-row">
                <mat-form-field appearance="fill">
                  <mat-label>
                    <span translate *ngIf="!isBundle">Nano slot amount</span>
                    <span translate *ngIf="isBundle">Bundle token amount</span>
                  </mat-label>
                  <input matInput formControlName="number" type="number" (change)="amountChanged($event)" />
                  <mat-error *ngIf="basketForm.get('number').errors?.['required']"><span translate>Please enter a number.</span></mat-error>
                  <mat-error *ngIf="basketForm.get('number').errors?.['isNumber']"><span translate>Value must be a number.</span></mat-error>
                  <mat-error *ngIf="basketForm.get('number').errors?.['min']"><span translate>The number must be larger than 0.</span></mat-error>
                  <mat-error *ngIf="basketForm.get('number').errors?.['max']"
                    ><span translate>The number can not exceed the maximum slot or bundle number.</span> ({{basketForm.get('number').errors?.['max'].max}})</mat-error
                  >
                </mat-form-field>
                <small class="explain-row" *ngIf="!isBundle" translate>The nano slot amount determines how many Nano clients can be concurrently connected to the web application.</small>
                <small class="explain-row" *ngIf="isBundle" translate>
                  Each bundle token serves a single Nano slot. The nano slot amount determines how many Nano clients can be concurrently connected to the web application.</small
                >
                <small class="explain-row" translate
                  >Please note that the maximum number of slots you can own is <b>{{ accountLicenseSlotLimit }}</b
                  >. Any purchased slots above <b>{{ accountLicenseSlotLimit }}</b> will be unavailable.</small
                >
                <small class="explain-row">
                  <p translate>
                    It is possible to purchase a Nano license as a company, priced at HUF 100,000 + VAT. Depending on the quantity and license duration, we offer a discounted price to our customers.
                  </p>
                  <p translate>For more information or to place an order, please contact us at <a href="mailto:penzugy@calarabot.eu">penzugy&#64;calarabot.eu</a>.</p>
                </small>
              </div>
            </form>

            <div class="button-row">
              <button mat-raised-button color="primary" matStepperNext (click)="basketForm.get('package').markAsTouched(); scrollToError()"><span translate>Next</span></button>
            </div>
          </mat-step>

          <mat-step [stepControl]="billingInfoForm">
            <div class="license-form-row one-row" *ngIf="paymentBillings?.length > 0">
              <mat-form-field appearance="fill">
                <mat-label><span translate>Saved billing templates</span></mat-label>
                <mat-select [(ngModel)]="selectedBilling" (selectionChange)="preFillBillingInfo()">
                  <mat-option [value]="null"><span translate>New information</span></mat-option>
                  <mat-option *ngFor="let paymentBilling of paymentBillings" [value]="paymentBilling"
                    >{{ paymentBilling.billing_id + 1 }}: {{ paymentBilling.postal }} {{ paymentBilling.city }}, {{ paymentBilling.address }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <div class="one-row-button">
                <button mat-raised-button color="primary" (click)="openBillingsDialog()"><span translate>Edit</span></button>
              </div>
            </div>

            <form [formGroup]="billingInfoForm">
              <ng-template matStepLabel><span translate>Billing Information</span></ng-template>

              <div class="license-form-row">
                <mat-card appearance="outlined">
                  <mat-card-header
                    ><mat-card-title><span translate>Purchase as</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <mat-radio-group class="form-radio-group" color="primary" formControlName="entity" (change)="entitySelectionChanged($event.value)">
                      <mat-radio-button class="form-radio-button" [value]="'individual'">
                        <div class="form-title" translate>Individual</div>
                        <div class="form-description" translate>Purchase license as an individual.</div>
                      </mat-radio-button>
                      <mat-radio-button class="form-radio-button" [value]="'company'">
                        <div class="form-title" translate>Company</div>
                        <div class="form-description" translate>Purchase license as a company.</div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="license-form-row">
                <mat-form-field>
                  <mat-label><span translate>Email</span></mat-label>
                  <input matInput formControlName="email" type="email" />
                  <mat-error *ngIf="billingInfoForm.get('email').errors?.['required']"><span translate>Please enter your email address.</span></mat-error>
                  <mat-error *ngIf="billingInfoForm.get('email').errors?.['email'] || billingInfoForm.get('email').errors?.['emailFormat']"
                    ><span translate>Please enter a valid email address.</span></mat-error
                  >
                </mat-form-field>
              </div>
              <div class="license-form-row">
                <mat-form-field>
                  <mat-label>
                    <span translate *ngIf="!isCompany">Name</span>
                    <span translate *ngIf="isCompany">Company name</span>
                  </mat-label>
                  <input matInput formControlName="name" type="text" />
                  <mat-error *ngIf="billingInfoForm.get('name').errors?.['required']">
                    <span translate *ngIf="!isCompany">Please enter your name.</span>
                    <span translate *ngIf="isCompany">Please enter your company name</span>
                  </mat-error>
                  <mat-error *ngIf="billingInfoForm.get('name').errors?.['maxLength']"><span>Name too long.</span></mat-error>
                </mat-form-field>
              </div>
              <div class="license-form-row" *ngIf="isCompany">
                <mat-form-field>
                  <mat-label><span translate>Name of purchaser</span></mat-label>
                  <input matInput formControlName="name_of_purchaser" type="text" />
                  <mat-error *ngIf="billingInfoForm.get('name_of_purchaser').errors?.['required']"><span translate>Please enter the name of the purchaser.</span></mat-error>
                  <mat-error *ngIf="billingInfoForm.get('name_of_purchaser').errors?.['maxLength']"><span translate>Name too long.</span></mat-error>
                </mat-form-field>
              </div>
              <div class="license-form-row">
                <mat-form-field>
                  <mat-label><span translate>Country code</span></mat-label>
                  <mat-select formControlName="country" (selectionChange)="countrySelectionChanged($event)">
                    <mat-option *ngFor="let countryCode of countryCodes | keyvalue" [value]="countryCode.key">{{ countryCode.key }} - {{ countryCode.value }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="billingInfoForm.get('country').errors?.['required']"><span translate>Please select your country.</span></mat-error>
                </mat-form-field>
              </div>
              <div class="license-form-row" *ngIf="billingInfoForm.get('country').value === 'US'">
                <mat-form-field>
                  <mat-label><span translate>State / County</span></mat-label>
                  <mat-select formControlName="state">
                    <mat-option *ngFor="let state of states" [value]="state.name">{{ state.name }} ({{ state.abbreviation }})</mat-option>
                  </mat-select>
                  <mat-error *ngIf="billingInfoForm.get('state').errors?.['required']"><span translate>Please select your state / county.</span></mat-error>
                </mat-form-field>
              </div>
              <div class="license-form-row" *ngIf="billingInfoForm.get('country').value !== 'US'">
                <mat-form-field>
                  <mat-label><span translate>State / County</span></mat-label>
                  <input matInput formControlName="state" type="text" />
                </mat-form-field>
              </div>
              <div class="license-form-row">
                <mat-form-field>
                  <mat-label><span translate>ZIP code</span></mat-label>
                  <input matInput formControlName="postal" type="text" [matAutocomplete]="hunZipCodes" />
                  <mat-error *ngIf="billingInfoForm.get('postal').errors?.['required']"><span translate>Please enter your ZIP code.</span></mat-error>
                  <mat-error *ngIf="billingInfoForm.get('postal').errors?.['maxLength']"><span translate>ZIP code too long.</span></mat-error>
                  <mat-autocomplete autoActiveFirstOption #hunZipCodes="matAutocomplete" (optionSelected)="onZipAutocompleteChange($event)">
                    <mat-option *ngFor="let postal of filteredPostalCodes" [value]="postal"> {{ postal.postal }} - {{ postal.city }} ({{ postal.county }}) </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="license-form-row">
                <mat-form-field>
                  <mat-label><span translate>City</span></mat-label>
                  <input matInput formControlName="city" type="text" />
                  <mat-error *ngIf="billingInfoForm.get('city').errors?.['required']"><span translate>Please enter your ZIP code.</span></mat-error>
                  <mat-error *ngIf="billingInfoForm.get('city').errors?.['maxLength']"><span translate>ZIP code too long.</span></mat-error>
                </mat-form-field>
              </div>
              <div class="license-form-row">
                <mat-form-field>
                  <mat-label><span translate>Address</span></mat-label>
                  <input matInput formControlName="address" type="text" />
                  <mat-error *ngIf="billingInfoForm.get('address').errors?.['required']"><span translate>Please enter your address.</span></mat-error>
                  <mat-error *ngIf="billingInfoForm.get('address').errors?.['maxLength']"><span translate>Address too long.</span></mat-error>
                </mat-form-field>
              </div>
              <div class="license-form-row tax-row" *ngIf="isCompany">
                <span class="tax-prefix" *ngIf="isCountryPrefixRequiredForTaxNumber()">{{ billingInfoForm.get('country').value }}</span>
                <mat-form-field>
                  <mat-label><span translate>Tax number</span></mat-label
                  ><input matInput formControlName="tax_number" type="text" />
                  <mat-error *ngIf="billingInfoForm.get('tax_number').errors?.['required']"><span translate>Please enter the tax number.</span></mat-error>
                  <mat-error *ngIf="billingInfoForm.get('tax_number').errors?.['maxLength']"><span translate>Tax number too long.</span></mat-error>
                  <mat-error *ngIf="billingInfoForm.get('tax_number').errors?.['taxFormatError']"><span translate>Tax number format invalid, please check the instructions below.</span></mat-error>
                </mat-form-field>
              </div>
              <div class="license-form-row tax-errors" *ngIf="taxErrors">
                <mat-card appearance="outlined">
                  <mat-card-header>
                    <mat-card-title><span translate>Tax number valid formats</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <mat-list>
                      <mat-list-item *ngFor="let example of taxExamples">{{ example }}</mat-list-item>
                    </mat-list> </mat-card-content
                  ><mat-card-header>
                    <mat-card-title><span translate>Tax number characters</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <mat-list>
                      <mat-list-item *ngFor="let char of taxCharMap | keyvalue">{{ char.key }}: {{ char.value }}</mat-list-item>
                    </mat-list>
                  </mat-card-content>
                </mat-card>
              </div>
            </form>

            <div class="button-row">
              <mat-checkbox color="primary" [(ngModel)]="saveToTemplate">
                <span translate *ngIf="!selectedBilling">Save information to templates</span>
                <span translate *ngIf="selectedBilling">Update template information</span>
              </mat-checkbox>
            </div>

            <div class="button-row">
              <button mat-button matStepperPrevious><span translate>Back</span></button>
              <button mat-raised-button color="primary" matStepperNext (click)="scrollToError()"><span translate>Next</span></button>
            </div>
          </mat-step>

          <mat-step [stepControl]="paymentForm">
            <form [formGroup]="paymentForm">
              <ng-template matStepLabel><span translate>Payment Form</span></ng-template>
              <div class="license-form-row">
                <mat-card appearance="outlined">
                  <mat-card-header>
                    <mat-card-title><span translate>Payment method</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <mat-radio-group class="form-radio-group" color="primary" formControlName="method">
                      <mat-radio-button class="form-radio-button" [value]="'card'">
                        <div class="form-title" translate>Credit or Debit card</div>
                        <div class="form-description" translate>Purchase with a credit or debit card.</div>
                      </mat-radio-button>
                      <mat-radio-button class="form-radio-button" [value]="'wire'" *ngIf="isCompany && isWirePriceMet">
                        <div class="form-title" translate>Bank transfer</div>
                        <div class="form-description" translate>Purchase through bank credit transfer.</div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="license-form-row">
                <mat-card appearance="outlined"
                  ><mat-card-header>
                    <mat-card-title><span translate>Recurring payment</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <mat-radio-group class="form-radio-group" color="primary" formControlName="payment_recurring" (change)="paymentRecurringSelectionChanged($event)">
                      <mat-radio-button class="form-radio-button" [value]="false">
                        <div class="form-title" translate>Purchase once</div>
                        <div class="form-description" translate>Purchase once without recurring payment. Once the license expires, you will have to purchase a new one.</div>
                      </mat-radio-button>
                      <mat-radio-button class="form-radio-button" [value]="true" [disabled]="!canPaymentBeRecurring">
                        <ng-container *ngIf="canPaymentBeRecurring">
                          <div class="form-title" translate>Purchase as recurring</div>
                          <div class="form-description" translate>Purchase with recurring payments. Once the license expires, it will be automatically bought again.</div>
                        </ng-container>
                        <ng-container *ngIf="!canPaymentBeRecurring">
                          <div class="form-title" translate>Purchase as recurring</div>
                          <div class="form-description" translate>
                            Selected license can't be bought as recurring. Only certain licenses, or licenses bought for yourself only can be purchased as recurring.
                          </div>
                        </ng-container>
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-form-field class="form-radio-extra-input" *ngIf="paymentForm.get('payment_recurring').value === true">
                      <mat-label><span translate>Recurring number</span></mat-label>
                      <input matInput formControlName="recurring_number" type="text" />
                      <mat-error *ngIf="paymentForm.get('recurring_number')?.errors?.['required']"><span translate>Please enter the recurring number.</span></mat-error>
                      <mat-error *ngIf="paymentForm.get('recurring_number')?.errors?.['isNumber']"><span translate>Recurring number must be a number.</span></mat-error>
                      <mat-error *ngIf="paymentForm.get('recurring_number')?.errors?.['min']"><span translate>Recurring number must be 1 or larger.</span></mat-error>
                      <mat-error *ngIf="paymentForm.get('recurring_number')?.errors?.['max']"
                        ><span translate
                          >Recurring number must be <span>{{ shopData.recurring_max }}</span> or smaller.</span
                        ></mat-error
                      >
                    </mat-form-field>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="license-form-row inverse">
                <mat-card appearance="outlined">
                  <mat-card-header>
                    <mat-card-title>
                      <div id="simple-pay-logo-container">
                        <a target="_blank" [href]="'http://simplepartner.hu/PaymentService/Payment_information.pdf' | translate"><img src="/assets/img/ui/simplepay_bankcard_logos_top_02.png" /></a>
                      </div> </mat-card-title
                  ></mat-card-header>
                  <mat-card-content>
                    <ng-container *ngIf="paymentForm.get('payment_recurring').value === true">
                      <p translate>
                        Recurring credit card payments ("Recurring Payments") are a feature of credit card acceptance provided by SimplePay, which means that new payments can be initiated in the
                        future with the credit card information provided by the Customer during the purchase transaction without re-entering the credit card information.
                      </p>
                      <p translate>
                        By accepting this statement, you consent to the use of Clarabot Zrt. After the successful purchase transaction (Address: Hungary, 1118 Budapest, Ménesi út 22., Tax number:
                        26539977-2-43, Cg. Number: 01-10-). 141527) (available from www.clarabot.com at https://nano.clarabot.com/licenses) should be initiated by the Merchant without re-entering your
                        credit card details and consenting to your transaction.
                      </p>
                      <p translate>Attention(!): Credit card data is handled in accordance with the card company rules. Neither the Merchant nor SimplePay has access to credit card information.</p>
                      <p translate>
                        The Merchant is directly responsible for recurring payment transactions initiated by the Merchant incorrectly or unlawfully, and any claims against the Merchant's payment
                        service provider (SimplePay) are excluded.
                      </p>
                      <mat-checkbox color="primary" formControlName="simple_pay_accepted">
                        <span translate> I have read this disclaimer and I acknowledge and accept its contents. </span>
                      </mat-checkbox>
                      <mat-error *ngIf="paymentForm.get('simple_pay_accepted').errors?.['required'] && paymentForm.get('simple_pay_accepted').touched"
                        ><span translate>Please accept the disclaimer.</span></mat-error
                      >
                    </ng-container>
                    <ng-container>
                      <mat-checkbox color="primary" formControlName="terms_of_service_accepted">
                        <span translate>
                          I accept the Terms and Conditions, the Privacy Policy, and the General Terms and Conditions for digitally available Nano licenses.
                          <a href="https://www.clarabot.com/legal/" target="_blank" translate>These documents can be read here</a>
                        </span>
                      </mat-checkbox>
                      <mat-error *ngIf="paymentForm.get('terms_of_service_accepted').errors?.['required'] && paymentForm.get('terms_of_service_accepted').touched"
                        ><span translate>Please accept the terms and conditions.</span></mat-error
                      >
                    </ng-container>
                    <ng-container *ngIf="isLotteryDiscountCode">
                      <mat-checkbox color="primary" formControlName="lottery_accepted">
                        <span translate>
                          I've read and accept the <a href="https://www.clarabot.com/files/legal/Nyerj_PS5-ot_a_NANO-val.pdf" target="_blank" translate>terms</a> of the lottery. I wish to participate
                          in the lottery game.
                        </span>
                      </mat-checkbox>
                      <mat-error *ngIf="paymentForm.get('lottery_accepted').errors?.['required'] && paymentForm.get('lottery_accepted').touched"
                        ><span translate>Please accept the lottery game terms and conditions.</span></mat-error
                      >
                    </ng-container>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="license-form-row discount-row">
                <mat-form-field>
                  <mat-label><span translate>Discount code</span></mat-label>
                  <input matInput type="text" formControlName="buy_code" />
                </mat-form-field>
                <div>
                  <button mat-raised-button color="primary" [disabled]="!paymentForm.get('buy_code').dirty" (click)="updateBuyCode()"><span translate>Update discount code</span></button>
                </div>
              </div>

              <div class="button-row">
                <button mat-button matStepperPrevious><span translate>Back</span></button>
                <button
                  mat-raised-button
                  color="primary"
                  matStepperNext
                  (click)="
                    paymentForm.get('terms_of_service_accepted').markAsTouched();
                    paymentForm.get('simple_pay_accepted')?.markAsTouched();
                    paymentForm.get('lottery_accepted')?.markAsTouched();
                    scrollToError()
                  "
                >
                  <span translate>Next</span>
                </button>
              </div>
            </form>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel><span translate>Finalize order</span></ng-template>
            <ng-container *ngIf="preparedPaymentData">
              <div class="license-form-row margin-top" *ngIf="basketForm.get('sandbox_payment').value">
                <mat-card appearance="outlined">
                  <mat-card-content>
                    <b class="larger" translate>This is a Sandbox purchase!</b>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="license-form-row margin-top">
                <mat-card appearance="outlined"
                  ><mat-card-header>
                    <mat-card-title><span translate>Order details</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <div class="order-info-col">
                      <label translate>Product</label>
                      <span>{{ basketForm.get('package').value.value.months }} <span translate>month license</span></span>
                    </div>
                    <div class="order-info-col">
                      <label translate>License target</label>
                      <span>
                        <span *ngIf="!isBundle" translate>Self</span>
                        <span *ngIf="isBundle" translate>Bundle</span>
                      </span>
                    </div>
                    <div class="order-info-col">
                      <label translate *ngIf="!isBundle">Slot amount</label>
                      <label translate *ngIf="isBundle">Bundle token amount</label>
                      <span *ngIf="preparedPaymentData.order.number > 1">
                        {{ preparedPaymentData.order.number }}
                        <span translate *ngIf="!isBundle">slots</span>
                        <span translate *ngIf="isBundle">bundle tokens</span>
                      </span>
                      <span *ngIf="preparedPaymentData.order.number === 1">
                        {{ preparedPaymentData.order.number }}
                        <span translate *ngIf="!isBundle">slot</span>
                        <span translate *ngIf="isBundle">bundle token</span>
                      </span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <div class="license-form-row">
                <mat-card appearance="outlined"
                  ><mat-card-header>
                    <mat-card-title><span translate>Billing details</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <div class="order-info-col">
                      <label translate>Purchaser entity</label>
                      <span *ngIf="preparedPaymentData.order.entity === 'individual'" translate>Individual</span>
                      <span *ngIf="preparedPaymentData.order.entity === 'company'" translate>Company</span>
                    </div>
                    <div class="order-info-col">
                      <label translate>Email</label>
                      <span>{{ preparedPaymentData.order.email }}</span>
                    </div>
                    <div class="order-info-col">
                      <label *ngIf="preparedPaymentData.order.entity === 'individual'" translate>Name</label>
                      <label *ngIf="preparedPaymentData.order.entity === 'company'" translate>Company name</label>
                      <span>{{ preparedPaymentData.order.name }}</span>
                    </div>
                    <div class="order-info-col" *ngIf="preparedPaymentData.order.entity === 'company'">
                      <label translate>Name of purchaser</label>
                      <span>{{ preparedPaymentData.order.name_of_purchaser }}</span>
                    </div>
                    <div class="order-info-col">
                      <label translate>Country code</label>
                      <span>{{ preparedPaymentData.order.country }}</span>
                    </div>
                    <div class="order-info-col">
                      <label translate>State / Province</label>
                      <span>{{ preparedPaymentData.order.state }}</span>
                    </div>
                    <div class="order-info-col">
                      <label translate>ZIP code</label>
                      <span>{{ preparedPaymentData.order.postal }}</span>
                    </div>
                    <div class="order-info-col">
                      <label translate>City</label>
                      <span>{{ preparedPaymentData.order.city }}</span>
                    </div>
                    <div class="order-info-col">
                      <label translate>Address</label>
                      <span>{{ preparedPaymentData.order.address }}</span>
                    </div>
                    <div class="order-info-col" *ngIf="preparedPaymentData.order.entity === 'company'">
                      <label translate>Tax number</label>
                      <span>{{ preparedPaymentData.order.tax_number }}</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <div class="license-form-row">
                <mat-card appearance="outlined"
                  ><mat-card-header>
                    <mat-card-title><span translate>Payment details</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <div class="order-info-col">
                      <label translate>Payment method</label>
                      <span *ngIf="preparedPaymentData.order.method === 'card'" translate>Credit or debit card</span>
                      <span *ngIf="preparedPaymentData.order.method === 'wire'" translate>Bank transfer</span>
                    </div>
                    <div class="order-info-col">
                      <label translate>Recurring payments</label>
                      <span *ngIf="preparedPaymentData.order.recurring_number === 0" translate>Purchase once</span>
                      <span *ngIf="preparedPaymentData.order.recurring_number > 0" translate
                        >Recurrences: <span>{{ preparedPaymentData.order.recurring_number }}</span></span
                      >
                    </div>
                    <div class="order-info-col" *ngIf="preparedPaymentData.order.discount_reference">
                      <label translate>Discount code</label>
                      <span>{{ preparedPaymentData.order.discount_reference }}</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <div class="license-form-row">
                <mat-card appearance="outlined">
                  <mat-card-header>
                    <mat-card-title><span translate>Detailed price</span></mat-card-title></mat-card-header
                  >
                  <mat-card-content>
                    <div class="price-row">
                      {{ preparedPaymentData.order.price_net | currency : preparedPaymentData.order.currency : 'symbol' : (isHunCurrency ? '1.0-2' : '') }}
                      + {{ preparedPaymentData.order.price_gross - preparedPaymentData.order.price_net | currency : preparedPaymentData.order.currency : 'symbol' : (isHunCurrency ? '1.0-2' : '') }}
                      <span translate>VAT</span>
                      = <b>{{ preparedPaymentData.order.price_gross | currency : preparedPaymentData.order.currency : 'symbol' : (isHunCurrency ? '1.0-2' : '') }}</b>
                    </div>
                    <div class="discount-row" *ngIf="preparedPaymentData.order.net_discount_percent">
                      <span translate>Final price includes the following discount:</span> {{ preparedPaymentData.order.net_discount_percent }}%
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </ng-container>

            <div class="button-row payment-button-row-container">
              <span class="payment-row-image">
                <a target="_blank" [href]="'http://simplepartner.hu/PaymentService/Payment_information.pdf' | translate"
                  ><img class="simple-pay-icon" src="/assets/img/ui/simplepay_otp_hu_top.png"
                /></a>
              </span>
              <span class="payment-button-row">
                <button mat-button matStepperPrevious><span translate>Back</span></button>
                <a [href]="paymentUrl" target="_BLANK"
                  ><button mat-raised-button class="checkout-button" color="primary" (click)="checkoutCompleted = true"><span translate>Checkout</span></button></a
                >
              </span>
            </div>
          </mat-step>
        </mat-stepper>
      </ng-container>

      <ng-container *ngIf="checkoutCompleted">
        <mat-card appearance="outlined"
          ><mat-card-header>
            <mat-card-title><span translate>Checkout completed</span></mat-card-title></mat-card-header
          >
          <mat-card-content
            ><span translate>The checkout process on this end is finished and handed over to the simple-pay procedure. The procedure continues in the opened window.</span></mat-card-content
          >
        </mat-card>
      </ng-container>
    </ng-container>
  </div>
</div>
